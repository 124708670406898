import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import qs from 'qs';

import Logo from 'src/components/logo';
import SEO from 'src/components/seo';
import routes from 'src/config/routes';

import * as DesignSystem from 'src/components/design-system';

import SimpleInput from 'src/components/design-system/form/input';
import LoginLayout from 'src/components/layouts/login';

import useForm from 'src/hooks/useForm';

const ResetPage = ({ location }) => {
  const { token } = location.search ? qs.parse(location.search.slice(1)) : {};

  const { formData, setFormData, requestState, submitForm } = useForm({
    initialValue: { email: '', password: '', password_confirmation: '', token },
    axiosConfig: {
      method: 'POST',
      url: routes.resetStep2(),
    },
    redirectUrl: `/`,
  });

  useEffect(() => {
    // No token... no business here...
    if (!token) navigate('/');
  }, [token]);

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  return (
    <LoginLayout>
      <SEO title="Changement de mot de passe" />

      <DesignSystem.Card>
        <div className="">
          <Logo className="m-auto h-36" />
          <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900 leading-9">
            Mise à jour du mot de passe
          </h2>
        </div>

        <div className="">
          <div className="mt-4">
            <form action="#" method="POST" onSubmit={submitForm}>
              <div>
                <label
                  for="email"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Adresse email
                </label>
                <SimpleInput
                  id="email"
                  value={formData.email}
                  onChange={updateTextField('email')}
                  className="mt-1"
                  requestState={requestState}
                />
              </div>

              <div className="mt-4">
                <label
                  for="password"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Nouveau mot de passe
                </label>

                <SimpleInput
                  id="password"
                  type="password"
                  className="mt-1"
                  value={formData.password}
                  onChange={updateTextField('password')}
                  requestState={requestState}
                />
              </div>

              <div className="mt-4">
                <label
                  for="password"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Confirmation du mot de passe
                </label>
                <SimpleInput
                  id="password_confirmation"
                  type="password"
                  className="mt-1"
                  value={formData.password_confirmation}
                  onChange={updateTextField('password_confirmation')}
                  requestState={requestState}
                />
              </div>

              <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                  >
                    Réinitialiser le mot de passe
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </DesignSystem.Card>
    </LoginLayout>
  );
};

export default ResetPage;
